import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { CmsContext } from 'logic/context/cms';
import { App } from 'app/app';
import { Seo } from 'components/seo/seo';

const NewsPage: FunctionComponent<any> = ({ data: { contentfulNewsPage } }) => {
  return (
    <CmsContext.Provider value={contentfulNewsPage}>
      <Seo
        title={contentfulNewsPage.metaTags.metatitle}
        description={contentfulNewsPage.metaTags.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsPage {
    contentfulNewsPage {
      headline
      metaTags {
        metatitle
        metadescription
      }
      newsEntries {
        ... on ContentfulNewsEntry {
          image {
            fluid(quality: 100, background: "rgb:000000") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          headline
          author
          date
          link
          category
        }
      }
    }
  }
`;
